import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query getUsers($limit: Int, $skip: Int, $sort: JSON, $where: JSON) {
    users(limit: $limit, skip: $skip, sort: $sort, where: $where) {
      id
      nonce
      role
      userName
      lastLogin
      createdAt
      updatedAt
    }
  }
`;

export const COUNT_USERS = gql`
  query countUsers($where: JSON) {
    countUsers(where: $where) {
      count
    }
  }
`;
