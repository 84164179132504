import { gql } from "@apollo/client";

export const GET_CASK_ISSUES = gql`
  query caskIssues($limit: Int, $skip: Int, $sort: JSON, $where: JSON) {
    caskIssues(limit: $limit, skip: $skip, sort: $sort, where: $where) {
      _id
      status
      creator
      commissionInfo
      cover
      img
      name
      desc
      info
      nonce
      updatedAt
      createdAt
    }
  }
`;

export const COUNT_CASK_ISSUES = gql`
  query countCaskIssues($where: JSON) {
    countCaskIssues(where: $where) {
      count
    }
  }
`;
