export enum SYSTEM_ROLES {
  ROOT_ROLE = "00000000",
  ADMIN_ROLE = "bd2e3ab1", // using CRC-32 hash `ADMIN_ROLE`
  USER_ROLE = "a8ebf748", // using CRC-32 hash `USER_ROLE`
  CASK_OWNER_ROLE = "260fd670", // using CRC-32 hash `CASK_OWNER_ROLE`
}

export enum CASK_OWNER_REQUEST_STATUS {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  NEW = "NEW",
}

export enum CASK_ISSUE_STATUS {
  NEW = "NEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  COMFIRMED_BLOCKCHAIN = "COMFIRMED_BLOCKCHAIN",
}
