import { gql } from "@apollo/client";

export const LOGIN_ADMIN_INFO = gql`
  mutation loginAdminInfo($input: GetLoginInfoInput!) {
    loginAdminInfo(input: $input) {
      message
      publicAddress
    }
  }
`;

export const LOGIN_ADMIN = gql`
  mutation loginAdmin($input: LoginInput!) {
    loginAdmin(input: $input) {
      id
      accessToken
      userName
    }
  }
`;
