import { useQuery } from "@apollo/client";
import {
  List,
  Table,
  ShowButton,
  Space,
  TableProps,
  Typography,
  Icons,
  Tooltip,
  Tag,
} from "@pankod/refine-antd";
import { BiLinkExternal } from "react-icons/bi";

import { COUNT_USERS, GET_USERS } from "apollo";
import { BLOCK_EXPLORER } from "common/contants";
import { IPost } from "interfaces";
import {
  countUsers,
  countUsersVariables,
  getUsers,
  getUsersVariables,
  getUsers_users,
} from "schemaTypes";
import { formatDate, timeSince } from "utils/date.utils";
import { SYSTEM_ROLES } from "common/enum";
import { getUserRole } from "utils/user.utils";
import { useNavigation, useResource } from "@pankod/refine-core";

const roleColor: any = {
  [SYSTEM_ROLES.CASK_OWNER_ROLE]: "green",
  [SYSTEM_ROLES.USER_ROLE]: "",
};

export const UserList: React.FC = () => {
  const userQuery = useQuery<getUsers, getUsersVariables>(GET_USERS, {
    variables: {
      limit: 20,
      skip: 0,
      sort: { createdAt: -1 },
      where: {},
    },
  });
  const countQuery = useQuery<countUsers, countUsersVariables>(COUNT_USERS, {
    variables: {
      where: {},
    },
  });

  const tableProps: TableProps<getUsers_users> = {
    dataSource: userQuery.data?.users || [],
    loading: userQuery.loading || countQuery.loading,
    scroll: { x: true },
    pagination: {
      pageSize: 20,
      total: countQuery.data?.countUsers.count || 0,
    },
    onChange(pagination, filters, sorter, extra) {
      const { current, pageSize } = pagination;

      userQuery.refetch({
        skip: ((current || 0) - 1) * (pageSize || 0),
      });
    },
  };

  const { showUrl: generateShowUrl, push } = useNavigation();
  const { resource } = useResource({});

  const navigateShow = (itemId: string) => {
    const showUrl = generateShowUrl(resource.route!, itemId!);
    push(showUrl);
  };

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title="Address"
          render={(value) => (
            <Typography.Link
              copyable
              // target="_blank"
              // href={`${BLOCK_EXPLORER}/address/${value}`}

              onClick={() => navigateShow(value)}
            >
              {value + " "}
              <BiLinkExternal className="text-top" size={18} />
            </Typography.Link>
          )}
        />
        <Table.Column dataIndex="userName" title="User name" />
        <Table.Column
          dataIndex="role"
          title="Role"
          render={(v) => <Tag color={roleColor[v]}>{getUserRole(v)}</Tag>}
        />
        <Table.Column
          dataIndex="lastLogin"
          title="Last login"
          render={(value) => (
            <Tooltip title={formatDate(value)}>
              <div>{timeSince(value)}</div>
            </Tooltip>
          )}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Created time"
          render={(value) => <div>{`${formatDate(value)}`}</div>}
        />

        {/* <Table.Column dataIndex="nonce" title="Nonce" /> */}
        <Table.Column<IPost>
          title="Actions"
          fixed="right"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <ShowButton
                  size="middle"
                  recordItemId={record.id}
                  hideText
                  icon={<Icons.InfoOutlined />}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
