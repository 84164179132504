import { Refine } from "@pankod/refine-core";
import {
  Layout,
  ReadyPage,
  notificationProvider,
  ErrorComponent,
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import { ApolloProvider } from "@apollo/client";
import { Icons } from "@pankod/refine-antd";
import { GiWineBottle } from "react-icons/gi";

import "@pankod/refine-antd/dist/styles.min.css";
import "./App.css";

import { client } from "apollo";
import {
  UserList,
  UserShow,
  CaskOwnerRequetList,
  CaskOwnerRequetShow,
  Login,
  CaskIssueRequetList,
  CaskIssueRequetShow,
} from "pages";
import { authProvider } from "provider/authProvider";

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <Refine
        LoginPage={Login}
        routerProvider={routerProvider}
        authProvider={authProvider}
        dataProvider={dataProvider("https://api.fake-rest.refine.dev")}
        Layout={Layout}
        ReadyPage={ReadyPage}
        notificationProvider={notificationProvider}
        catchAll={<ErrorComponent />}
        options={{
          syncWithLocation: true,
          warnWhenUnsavedChanges: true,
        }}
        resources={[
          // {
          //   name: "posts",
          //   list: PostList,
          //   show: PostShow,
          //   edit: PostEdit,
          //   create: PostCreate,
          //   canDelete: true,
          // },
          {
            name: "users",
            list: UserList,
            show: UserShow,
            icon: <Icons.UsergroupAddOutlined />,
          },
          { name: "cask-owners", options: { hide: false } },
          {
            name: "requests",
            parentName: "cask-owners",
            list: CaskOwnerRequetList,
            show: CaskOwnerRequetShow,
            icon: <Icons.FileProtectOutlined />,
          },
          {
            name: "nfts",
            icon: <GiWineBottle />,
            options: { label: "NFTs", hide: false },
          },
          {
            name: "cask-issue-requests",
            parentName: "nfts",
            options: { label: "Cask requets" },
            list: CaskIssueRequetList,
            show: CaskIssueRequetShow,
            icon: <Icons.FileProtectOutlined />,
          },
        ]}
      />
    </ApolloProvider>
  );
};

export default App;
