import dayjs from "dayjs";

export const formatDate = (date: Date | string) => {
  return dayjs(date).format("HH:mm MM/DD/YY");
};

export const timeSince = (date: Date | string) => {
  if (!date) return "";

  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  var seconds = Math.floor((Date.now() - date.getTime()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
};
