import {
  useRouterContext,
  ResourceRouterParams,
  useNavigation,
} from "@pankod/refine-core";
import { Show, Typography, Button, Space, Tag } from "@pankod/refine-antd";
import { useQuery } from "@apollo/client";

import { GET_CASK_OWNER_REQUESTS } from "apollo";
import { CASK_OWNER_REQUEST_STATUS as STATUS } from "common/enum";
import { caskOwnerRequests, caskOwnerRequestsVariables } from "schemaTypes";
import { formatDate, timeSince } from "utils/date.utils";
import { statusColor } from "pages/cask-owner-requests/List";

const { Title, Text, Paragraph } = Typography;

export const CaskIssueRequetShow = (props: any) => {
  return (
    <Show
      title={"Cask issue detail"}
      // isLoading={loading}
      canEdit={false}
      contentProps={{}}
    >
      Updating ...
    </Show>
  );
};
