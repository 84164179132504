import { useMutation, useQuery } from "@apollo/client";
import {
  List,
  Table,
  Space,
  TableProps,
  Typography,
  Tag,
  Icons,
  Button,
  Tooltip,
  Image,
} from "@pankod/refine-antd";
import { BiLinkExternal } from "react-icons/bi";
import { useNavigation, useResource, useShow } from "@pankod/refine-core";

import { COUNT_CASK_ISSUES, GET_CASK_ISSUES } from "apollo";
import { BLOCK_EXPLORER } from "common/contants";
import { CASK_ISSUE_STATUS } from "common/enum";
import {
  caskIssues,
  caskIssuesVariables,
  caskIssues_caskIssues,
  countCaskIssues,
  countCaskIssuesVariables,
} from "schemaTypes";
import { shortenAddress } from "utils/address.utils";
import { formatDate } from "utils/date.utils";
import { statusColor } from "./style";
import { useCaskIssueRequest } from "./hooks/cask-issue.hook";

export const CaskIssueRequetList: React.FC = () => {
  const caskIssueQuery = useQuery<caskIssues, caskIssuesVariables>(
    GET_CASK_ISSUES,
    {
      variables: {
        limit: 20,
        skip: 0,
        sort: { createdAt: -1 },
        where: {},
      },
    }
  );

  const countQuery = useQuery<countCaskIssues, countCaskIssuesVariables>(
    COUNT_CASK_ISSUES,
    {
      variables: {
        where: {},
      },
    }
  );

  const tableProps: TableProps<caskIssues_caskIssues> = {
    dataSource: caskIssueQuery.data?.caskIssues || [],
    loading: caskIssueQuery.loading || countQuery.loading,
    scroll: { x: "max-content" },
    pagination: {
      pageSize: 20,
      total: countQuery.data?.countCaskIssues.count || 0,
    },
    onChange(pagination, filters, sorter, extra) {
      const { current, pageSize } = pagination;
      caskIssueQuery.refetch({
        skip: ((current || 0) - 1) * (pageSize || 0),
      });
    },
  };

  const { showUrl: generateShowUrl, push } = useNavigation();
  const { resource } = useResource({});

  const navigateShow = (itemId: string) => {
    const showUrl = generateShowUrl(resource.route!, itemId!);
    push(showUrl);
  };
  const { approve, reject } = useCaskIssueRequest();

  return (
    <List>
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          dataIndex="_id"
          title="ID"
          render={(value) => (
            <Typography.Link onClick={() => navigateShow(value)}>
              {value}
            </Typography.Link>
          )}
        />
        <Table.Column
          dataIndex="creator"
          title="Cask Owner"
          render={(value) => (
            <Typography.Link
              href={`${BLOCK_EXPLORER}/address/${value}`}
              target="_blank"
              copyable={{ text: value }}
            >
              {shortenAddress(value) + " "}{" "}
              <BiLinkExternal className="text-top" size={16} />
            </Typography.Link>
          )}
        />
        {/* <Table.Column dataIndex="email" title="Email" /> */}
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="cover"
          title="Image"
          render={(v) => <Image width={200} src={v} />}
        />
        <Table.Column dataIndex="nonce" title="Project ID" />

        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value) => <Tag color={statusColor[value]}>{value}</Tag>}
          // filterDropdown={(props) => (
          //   <FilterDropdown {...props}>
          //     <Select
          //       style={{ minWidth: 200 }}
          //       mode="multiple"
          //       placeholder="Select status"
          //       options={Object.keys(STATUS).map((item) => ({
          //         lable: item,
          //         value: item,
          //       }))}
          //     />
          //   </FilterDropdown>
          // )}
        />
        {/* <Table.Column dataIndex="mobile" title="Mobile" /> */}
        {/* <Table.Column dataIndex="desc" title="Description" width={300} /> */}
        <Table.Column
          dataIndex="updatedAt"
          title="Updated time"
          render={(value) => <div>{`${formatDate(value)}`}</div>}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Created time"
          render={(value) => <div>{`${formatDate(value)}`}</div>}
        />

        <Table.Column<any>
          title="Actions"
          fixed="right"
          dataIndex="actions"
          render={(_text, record: caskIssues_caskIssues): React.ReactNode => {
            return (
              <Space>
                <Tooltip title="Approve">
                  <Button
                    disabled={record.status !== CASK_ISSUE_STATUS.NEW}
                    onClick={() => approve(record._id, record.creator)}
                  >
                    <Icons.CheckOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Reject">
                  <Button
                    disabled={record.status !== CASK_ISSUE_STATUS.NEW}
                    danger
                    onClick={() => reject(record._id, record.creator)}
                  >
                    <Icons.CloseOutlined />
                  </Button>
                </Tooltip>
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
