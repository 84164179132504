import { ethers } from "ethers";

export const signMessage = (message: string) => {
  if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    return signer.signMessage(message);
  } else {
    throw new Error("Not found Metamask");
  }
};
