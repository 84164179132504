import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPH_URL });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: { headers: any }) => {
    return {
      headers: {
        ...headers,
        // authorization: `Bearer ${fixedToken}`,
      },
    };
  });
  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink, httpLink]),
});

export const setToken = (token: string) => {
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: { headers: any }) => {
      return {
        headers: {
          ...headers,
          ...(token && {
            authorization: `Bearer ${token}`,
          }),
        },
      };
    });
    return forward(operation);
  });
  client.setLink(from([errorLink, authLink, httpLink]));
};

export const removeToken = (token: string) => {
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: { headers: any }) => {
      return {
        headers: {
          ...headers,
          authorization: "",
        },
      };
    });
    return forward(operation);
  });
  client.setLink(from([errorLink, authLink, httpLink]));
};
