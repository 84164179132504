import { gql } from "@apollo/client";

export const GET_CASK_OWNER_REQUESTS = gql`
  query caskOwnerRequests($limit: Int, $skip: Int, $sort: JSON, $where: JSON) {
    caskOwnerRequests(limit: $limit, skip: $skip, sort: $sort, where: $where) {
      _id
      owner
      desc
      email
      mobile
      name
      status
      updatedAt
      createdAt
    }
  }
`;

export const COUNT_CASK_OWNER_REQUESTS = gql`
  query countCaskOwnerRequests($where: JSON) {
    countCaskOwnerRequests(where: $where) {
      count
    }
  }
`;
