import { gql } from "@apollo/client";

export const HANDLE_CASK_ISSUE = gql`
  mutation handleCaskIssue($input: HandleCaskIssueInput!) {
    handleCaskIssue(input: $input) {
      _id
      status
      signature
    }
  }
`;
