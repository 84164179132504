import { useMutation } from "@apollo/client";
import { Modal, notification } from "@pankod/refine-antd";

import { HANDLE_CASK_ISSUE } from "apollo";
import { handleCaskIssue, handleCaskIssueVariables } from "schemaTypes";

export const useCaskIssueRequest = () => {
  const [request] = useMutation<handleCaskIssue, handleCaskIssueVariables>(
    HANDLE_CASK_ISSUE
  );

  const approve = (id: string, owner: string, cb?: Function) => {
    Modal.confirm({
      title: "Confirmation",
      content: `You will approve Cask Issue request for ${owner}`,
      onOk: async () => {
        await request({
          variables: {
            input: { _id: id, approved: true },
          },
        });

        notification.success({ message: "Successfully approved" });
        if (cb) cb();
      },
    });
  };
  const reject = (id: string, owner: string, cb?: Function) => {
    Modal.confirm({
      title: "Confirmation",
      content: `You will reject Cask Issue request for ${owner}`,
      onOk: async () => {
        await request({
          variables: {
            input: { _id: id, approved: false },
          },
        });

        notification.success({ message: "Successfully rejected" });
        if (cb) cb();
      },
    });
  };

  return { approve, reject };
};
