import {
  useShow,
  useOne,
  useShowProps,
  useRouterContext,
  ResourceRouterParams,
} from "@pankod/refine-core";
import { Show, Typography, Tag } from "@pankod/refine-antd";

import { ICategory } from "interfaces";
import { useQuery } from "@apollo/client";
import { GET_USERS } from "apollo";
import { getUsers, getUsersVariables } from "schemaTypes";
import { formatDate, timeSince } from "utils/date.utils";

const { Title, Text } = Typography;

export const UserShow = (props: any) => {
  const { useParams } = useRouterContext();

  const { resource: routeResourceName, id: idFromRoute } =
    useParams<ResourceRouterParams>();

  const { data, loading, error, refetch } = useQuery<
    getUsers,
    getUsersVariables
  >(GET_USERS, {
    variables: { where: { id: idFromRoute } },
  });

  return (
    <Show title={"User detail"} isLoading={loading} headerButtonProps={{}}>
      <Title level={5}>Title</Title>
      <Text>{data?.users[0]?.id}</Text>

      <Title level={5}>Last login</Title>
      <Text>
        {`${formatDate(data?.users[0]?.lastLogin)} (${timeSince(
          data?.users[0]?.lastLogin
        )} ago)`}
      </Text>

      <Title level={5}>Created At</Title>
      <Text>{`${formatDate(data?.users[0]?.createdAt)}`}</Text>

      <Title level={5}>Nonce</Title>
      <Text>{data?.users[0]?.nonce}</Text>
    </Show>
  );
};
