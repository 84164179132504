import { useLogin } from "@pankod/refine-core";
import {
  AntdLayout,
  Button,
  Row,
  Col,
  notification,
} from "@pankod/refine-antd";
import { useMutation } from "@apollo/client";

import {
  loginAdmin,
  loginAdminInfo,
  loginAdminInfoVariables,
  loginAdminVariables,
} from "schemaTypes";
import { LOGIN_ADMIN, LOGIN_ADMIN_INFO } from "apollo";
import { useEffect } from "react";
import { signMessage } from "utils/wallet";

export const Login: React.FC = () => {
  const { mutate: login, isLoading } = useLogin();

  const [getLoginAdminInfo, { loading: infoLoading, error: infoError }] =
    useMutation<loginAdminInfo, loginAdminInfoVariables>(LOGIN_ADMIN_INFO);

  const [loginAdmin, { data, loading, error }] = useMutation<
    loginAdmin,
    loginAdminVariables
  >(LOGIN_ADMIN);

  const clickLogin = async () => {
    if (window.ethereum && window.ethereum.request) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      if (!accounts[0]) {
        throw new Error("Not found your public address!");
      }

      const { data: infoData } = await getLoginAdminInfo({
        variables: {
          input: {
            publicAddress: accounts[0],
          },
        },
      });

      if (!infoData) throw new Error("");

      const singature = await signMessage(infoData.loginAdminInfo.message);

      const { data } = await loginAdmin({
        variables: {
          input: {
            publicAddress: infoData.loginAdminInfo.publicAddress,
            signature: singature,
          },
        },
      });

      login({ accessToken: data?.loginAdmin.accessToken });
    } else {
      notification.error({
        message:
          "Not set ethereum wallet or invalid. You need to install Metamask",
      });
    }
  };

  useEffect(() => {
    if (infoError) notification.error({ message: infoError.message });
    if (error) notification.error({ message: error.message });
  }, [infoError, error]);

  return (
    <AntdLayout
      style={{
        background: `radial-gradient(50% 50% at 50% 50%, #63386A 0%, #310438 100%)`,
        backgroundSize: "cover",
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "28px",
            }}
          >
            {/* <img src="./refine.svg" alt="Refine" /> */}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="primary"
              size="large"
              icon={
                <img
                  style={{ width: 25, marginRight: 15 }}
                  alt={"MetaMask logo"}
                  src="/images/metamask_logo.svg"
                />
              }
              loading={infoLoading || loading}
              onClick={clickLogin}
            >
              SIGN-IN WITH METAMASK
            </Button>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
