import { gql } from "@apollo/client";

export const HANDLE_CASK_OWNER_REQUEST = gql`
  mutation handleCaskOwnerRequest($input: HandleCaskOwnerRegisterInput!) {
    handleCaskOwnerRequest(input: $input) {
      _id
      owner
      email
      mobile
      desc
      status
      createdAt
      updatedAt
    }
  }
`;
