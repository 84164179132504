import {
  useRouterContext,
  ResourceRouterParams,
  useNavigation,
} from "@pankod/refine-core";
import { Show, Typography, Button, Space, Tag } from "@pankod/refine-antd";
import { useQuery } from "@apollo/client";

import { GET_CASK_OWNER_REQUESTS } from "apollo";
import { CASK_OWNER_REQUEST_STATUS as STATUS } from "common/enum";
import { caskOwnerRequests, caskOwnerRequestsVariables } from "schemaTypes";
import { formatDate, timeSince } from "utils/date.utils";
import { useCaskOwnerRequest } from "./hooks/cask-owner-request.hook";
import { statusColor } from "pages/cask-owner-requests/List";

const { Title, Text, Paragraph } = Typography;

export const CaskOwnerRequetShow = (props: any) => {
  const { useParams } = useRouterContext();

  const { goBack } = useNavigation();
  const { id: idFromRoute } = useParams<ResourceRouterParams>();

  const {
    data: res,
    previousData,
    loading,
    error,
    called,
  } = useQuery<caskOwnerRequests, caskOwnerRequestsVariables>(
    GET_CASK_OWNER_REQUESTS,
    {
      variables: {
        where: { _id: { $eq: idFromRoute } },
      },
    }
  );

  const data = res?.caskOwnerRequests[0];

  const { approve, reject } = useCaskOwnerRequest();

  const approveRequest = () => {
    approve(data?._id || "", data?.owner || "", goBack);
  };

  const rejectRequest = () => {
    reject(data?._id || "", data?.owner || "", goBack);
  };

  return (
    <Show
      title={"Request detail"}
      isLoading={loading}
      canEdit={false}
      contentProps={{
        actions: [
          data?.status === STATUS.NEW && (
            <Space
              key="footer-buttons"
              wrap
              style={{
                float: "left",
                marginLeft: 24,
              }}
            >
              <Button type="primary" size="large" onClick={approveRequest}>
                Approve
              </Button>
              <Button danger size="large" onClick={rejectRequest}>
                Reject
              </Button>
            </Space>
          ),
        ],
      }}
    >
      <Title level={5}>Request ID</Title>
      <Text>{data?._id}</Text>
      <Title level={5}>Status</Title>
      <Text>
        <Tag style={{ fontSize: 16 }} color={statusColor[data?.status || ""]}>
          {data?.status}
        </Tag>
      </Text>
      <Title level={5}>User address</Title>
      <Text>{data?.owner}</Text>
      <Title level={5}>Name</Title>
      <Text>{data?.name}</Text>
      <Title level={5}>Email</Title>
      <Text>{data?.email}</Text>
      <Title level={5}>Description</Title>
      <Paragraph>{data?.desc}</Paragraph>

      <Title level={5}>Request time</Title>
      <Text>
        {`${formatDate(data?.createdAt)} (${timeSince(data?.createdAt)} ago)`}
      </Text>
    </Show>
  );
};
