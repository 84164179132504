import { useMutation, useQuery } from "@apollo/client";
import {
  List,
  Table,
  ShowButton,
  Space,
  TableProps,
  Typography,
  Tag,
  Icons,
  Button,
  Tooltip,
} from "@pankod/refine-antd";
import { COUNT_CASK_ISSUES, GET_CASK_OWNER_REQUESTS } from "apollo";
import { BiLinkExternal } from "react-icons/bi";

import { BLOCK_EXPLORER } from "common/contants";
import { CASK_OWNER_REQUEST_STATUS as STATUS } from "common/enum";
import {
  caskOwnerRequests,
  caskOwnerRequestsVariables,
  caskOwnerRequests_caskOwnerRequests,
  countCaskIssues,
  countCaskIssuesVariables,
} from "schemaTypes";
import { shortenAddress } from "utils/address.utils";
import { formatDate } from "utils/date.utils";
import { useNavigation, useResource } from "@pankod/refine-core";
import { useCaskOwnerRequest } from "./hooks/cask-owner-request.hook";

export const statusColor: any = {
  [STATUS.APPROVED]: "green",
  [STATUS.REJECTED]: "red",
  [STATUS.NEW]: "blue",
};

export const CaskOwnerRequetList: React.FC = () => {
  const caskOwnerRequestQuery = useQuery<
    caskOwnerRequests,
    caskOwnerRequestsVariables
  >(GET_CASK_OWNER_REQUESTS, {
    variables: {
      limit: 20,
      skip: 0,
      sort: { updatedAt: -1 },
      where: {},
    },
  });
  const countQuery = useQuery<countCaskIssues, countCaskIssuesVariables>(
    COUNT_CASK_ISSUES,
    {
      variables: {
        where: {},
      },
    }
  );

  const tableProps: TableProps<caskOwnerRequests_caskOwnerRequests> = {
    dataSource: caskOwnerRequestQuery.data?.caskOwnerRequests || [],
    loading: caskOwnerRequestQuery.loading || countQuery.loading,
    scroll: { x: "max-content" },
    pagination: {
      pageSize: 20,
      total: countQuery.data?.countCaskIssues.count || 0,
    },
    onChange(pagination, filters, sorter, extra) {
      const { current, pageSize } = pagination;

      caskOwnerRequestQuery.refetch({
        skip: ((current || 0) - 1) * (pageSize || 0),
      });
    },
  };

  const { showUrl: generateShowUrl, push } = useNavigation();
  const { resource } = useResource({});

  const navigateShow = (itemId: string) => {
    const showUrl = generateShowUrl(resource.route!, itemId!);
    push(showUrl);
  };

  const { approve, reject } = useCaskOwnerRequest();

  return (
    <List>
      <Table {...tableProps} rowKey="_id">
        <Table.Column
          dataIndex="_id"
          title="ID"
          render={(value) => (
            <Typography.Link onClick={() => navigateShow(value)}>
              {value}
            </Typography.Link>
          )}
        />
        <Table.Column
          dataIndex="owner"
          title="Address"
          render={(value) => (
            <Typography.Link
              href={`${BLOCK_EXPLORER}/address/${value}`}
              target="_blank"
              copyable={{ text: value }}
            >
              {shortenAddress(value) + " "}{" "}
              <BiLinkExternal className="text-top" size={16} />
            </Typography.Link>
          )}
        />
        <Table.Column dataIndex="email" title="Email" />
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value) => <Tag color={statusColor[value]}>{value}</Tag>}
          // filterDropdown={(props) => (
          //   <FilterDropdown {...props}>
          //     <Select
          //       style={{ minWidth: 200 }}
          //       mode="multiple"
          //       placeholder="Select status"
          //       options={Object.keys(STATUS).map((item) => ({
          //         lable: item,
          //         value: item,
          //       }))}
          //     />
          //   </FilterDropdown>
          // )}
        />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="mobile" title="Mobile" />
        <Table.Column dataIndex="desc" title="Description" width={300} />
        <Table.Column
          dataIndex="updatedAt"
          title="Updated time"
          render={(value) => <div>{`${formatDate(value)}`}</div>}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Created time"
          render={(value) => <div>{`${formatDate(value)}`}</div>}
        />

        <Table.Column<any>
          title="Actions"
          fixed="right"
          dataIndex="actions"
          render={(
            _text,
            record: caskOwnerRequests_caskOwnerRequests
          ): React.ReactNode => {
            return (
              <Space>
                <Tooltip title="Approve">
                  <Button
                    disabled={record.status !== STATUS.NEW}
                    onClick={() => {
                      approve(record._id, record.owner);
                    }}
                  >
                    <Icons.CheckOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Reject">
                  <Button
                    disabled={record.status !== STATUS.NEW}
                    danger
                    onClick={() => {
                      reject(record._id, record.owner);
                    }}
                  >
                    <Icons.CloseOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Detail">
                  <ShowButton
                    icon={<Icons.InfoOutlined />}
                    size="middle"
                    recordItemId={record._id}
                    hideText
                  />
                </Tooltip>
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
