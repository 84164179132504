import { AuthProvider } from "@pankod/refine-core";

import { setToken } from "apollo";
import { TOKEN_STORAGE_KEY } from "common/contants";

export const authProvider: AuthProvider = {
  login: async ({ accessToken }: { accessToken: string }) => {
    localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);
    setToken(localStorage.getItem(TOKEN_STORAGE_KEY) || "");
    return;
  },
  register: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  logout: async () => {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  },
  checkAuth: async () => {
    return localStorage.getItem(TOKEN_STORAGE_KEY)
      ? Promise.resolve()
      : Promise.reject();
  },
  checkError: () => Promise.resolve(),
  getPermissions: async () => {
    // console.log("getPermissions");
    return;
  },
  getUserIdentity: async () => {
    setToken(localStorage.getItem(TOKEN_STORAGE_KEY) || "");
    return {};
  },
};
